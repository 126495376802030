import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

import ImgPortal from "../assets/images/portal-404.svg"

const NotFoundPage = () => (
  <Layout page="four-oh-four">
    <SEO title="404" />
    <h1>There’s nothing here.</h1>
    <img
      src={ImgPortal}
      className="portal-404"
      alt="archway leading into a surreal space"
    />
  </Layout>
)

export default NotFoundPage
